@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400);
/*!

=========================================================
* Used for client cache busting and custom styles
=========================================================

* Coded by Mike Arnett

=========================================================
*/

/* Start Custom Marker Settings */

.custom-marker {
    width: 150px;
    height: 150px;
    background: transparent;
    border: none;
    position: absolute;
}

.chart-text {
    font: 16px/1.4em "Montserrat", Arial, sans-serif;
    fill: #000;
    -moz-transform: translateY(0.25em);
    -ms-transform: translateY(0.25em);
    -webkit-transform: translateY(0.25em);
    transform: translateY(0.25em);
}

.chart-number {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    -moz-transform: translateY(-0.25em);
    -ms-transform: translateY(-0.25em);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
}

.chart-label {
    font-size: 0.35em;
    text-transform: uppercase;
    text-anchor: middle;
    -moz-transform: translateY(0.7em);
    -ms-transform: translateY(0.7em);
    -webkit-transform: translateY(0.7em);
    transform: translateY(0.7em);
}
/* END Custom Marker Settings */